import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Styles & Images
import 'src/components/input/scss/styles.scss'

// Partials:
const CheckIcon = ({ checked, disabled }) => (
  <i
    className={classNames('x__checkbox', {
      'x__checkbox--disabled': disabled,
    })}
  >
    {checked && <span className="x__checkbox-mark" />}
  </i>
)

// Main component:
const Checkbox = ({ id, variant, label, checked, disabled, onChange }) => {
  const handleOnClick = () => {
    !disabled && onChange(!checked)
  }

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 32) handleOnClick()
  }

  return (
    <div
      className={classNames(`x__input-check x__input-check--${variant}`, {
        'x__input-check--disabled': disabled,
        'x__input-check--checked': checked,
      })}
      onClick={handleOnClick}
      onKeyDown={handleInputKeyDown}
      tabIndex={0}
    >
      <CheckIcon checked={checked} disabled={disabled} />
      <span id={id} className="x__label">
        {label}
      </span>
      <input id={`input--${id}`} name={id} type="hidden" value={checked} disabled={disabled} />
    </div>
  )
}

// Main component props:
Checkbox.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  variant: 'primary',
  label: 'Checkbox label',
  checked: false,
  disabled: false,
}

export default Checkbox
